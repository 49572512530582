<template>
  <Div>
    <a-row>
      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24" class="auth-page-left-wrapper">
        <Aside>
          <div class="auth-side-content">
            <img :src="require('@/static/img/auth/topShape.png')" alt="" class="topShape" />
            <img :src="require('@/static/img/auth/bottomShape.png')" alt="" class="bottomShape" />
            <Content>
              <div class="auth-side-logo">
                <a class="striking-logo" :href="websiteUrl">
                  <img
                    :style="{ minWidth: '150px', marginTop: '5px' }"
                    :src="require('@/static/img/logo.svg')"
                    alt=""
                  />
                </a>
                <!--<sdHeading as="h1"> {{ i18n.t('authPage.title') }} </sdHeading>-->
              </div>
              <div class="auth-content-figure">
                <div class="hero_media">
                  <Vue3Lottie
                    class="lottie"
                    :animationData="lottieData"
                    :speed="0.5"
                    style="width: 100%; height: 100%"
                  />
                </div>
                <!--<img :src="require('@/static/img/auth/Illustration.svg')" alt="" />-->
                <!--<div class="auth-content-text">
                  <p class="auth-content-text__title">{{ i18n.t('authPage.tarifVarietyTitle') }}</p>
                  <p class="auth-content-text__description">{{ i18n.t('authPage.tarifVarietyText') }}</p>
                </div>-->
              </div>
            </Content>
          </div>
        </Aside>
      </a-col>
      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <router-view></router-view>
      </a-col>
      <Footer :authPage="true" />
    </a-row>
  </Div>
</template>
<script>
import { Aside, Content, Div } from './style';
import { useI18n } from 'vue-i18n';
import { computed, defineAsyncComponent, onMounted, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { Notification } from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';
import { getCookie, setCookie } from '@/utility/localStorageControl';
const Footer = defineAsyncComponent(() => import('./Footer'));
import lottieData from '@/core/plugins/lottie.json';

const AuthLayout = {
  name: 'AuthLayout',
  components: { Aside, Content, Footer, Div },
  setup() {
    const { state } = useStore();
    const i18n = useI18n();
    const route = useRoute();
    const router = useRouter();
    let language;

    const websiteUrl = computed(() => process.env.VUE_APP_WEBSITE_APP_URL);

    const expireToken = computed(() => state.auth.isExpireToken);
    watch(expireToken, (value) => {
      if (value) {
        Notification.open({
          message: i18n.t('messages.expireTokenTitle'),
          description: i18n.t('messages.expireTokenDescription'),
          duration: 6,
        });
      }
    });
    if (getCookie('language') === 'ru' || getCookie('language') === 'en' || route.query.lang) {
      i18n.locale.value = route.query.lang ?? getCookie('language');
      if (route.query.lang) {
        setCookie({ name: 'language', value: route.query.lang });
      }
    } else {
      language =
        window.navigator.language ?? window.navigator.systemLanguage ?? window.navigator.userLanguage ?? 'en-US';
      switch (language) {
        case 'en-US' || 'en':
          language = 'en';
          break;
        case 'ru' || 'ru-RU':
          language = 'ru';
          break;
        default:
          language = 'en';
      }
      i18n.locale.value = language;
      setCookie({ name: 'language', value: language });
    }
    onMounted(() => {
      document.title = `${i18n.t('menuItems.' + route.name)} | MTHUB`;
      router.beforeEach((to, from, next) => {
        if (to.name) {
          document.title = `${i18n.t('menuItems.' + to.name)} | MTHUB`;
        } else {
          document.title = `${i18n.t('menuItems.' + to.params.actionType)} | MTHUB`;
        }
        next();
      });
    });
    return { i18n, expireToken, lottieData, websiteUrl };
  },
};

export default AuthLayout;
</script>
<style lang="scss" scoped>
.auth-content-figure {
  @media screen and (max-width: 900px) {
    display: none;
  }
}
.a-row {
  height: 100%;
  overflow: hidden;
}
.auth-page-left-wrapper {
  @media screen and (max-width: 500px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 110%;
    z-index: -1;
    height: 100%;
    aside {
      background: #fff;
      img:nth-child(2) {
        display: none;
      }
    }
  }
}
.auth-side-logo {
  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 20px;
      margin-top: 0;
    }
  }
}
</style>
